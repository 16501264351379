define("discourse/plugins/lazy-yt/initializers/lazyYT", ["exports", "discourse/lib/plugin-api", "discourse/plugins/lazy-yt/lib/lazyYT"], function (_exports, _pluginApi, _lazyYT) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "apply-lazyYT",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", api => {
        (0, _lazyYT.default)($);
        api.decorateCooked($elem => {
          const iframes = $(".lazyYT", $elem);
          if (iframes.length === 0) {
            return;
          }
          $(".lazyYT", $elem).lazyYT({
            onPlay(e, $el) {
              // don't cloak posts that have playing videos in them
              const postId = parseInt($el.closest("article").data("post-id"), 10);
              if (postId) {
                api.preventCloak(postId);
              }
            }
          });
        }, {
          id: "discourse-lazyyt"
        });
      });
    }
  };
});